<template>
  <div style="padding-bottom: 90px">
    <HomePageTop v-if="cart"></HomePageTop>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div :class="cart ? 'main' : ' main hei'">
      <div class="type" v-if="cart">
        <button :class="{ isbtn: type == 0 }" style="font-weight: 500;">
          <!-- 购物车 -->{{ $fanyi('购物车') }}
        </button>
        <!-- 1688 -->
        <div class="1688img-box">
          <a href="https://sale.1688.com/" target="_blank">
            <img src="../../assets/AlibabaproductDetail.svg" alt=""></a>
        </div>
      </div>
      <div class="head" :style="$route.name == 'cart' ? 'top: 60px;' : ''">
        <ul>
          <li class="chexk-box" style="font-size: 14px">
            <input type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
            <span>{{ $fanyi('全选') }}</span>
          </li>
          <li class="goods"><!-- 产品 -->{{ $fanyi('产品') }}</li>
          <li class="detail"><!-- 详情 -->{{ $fanyi('详情') }}</li>
          <li><!-- 单价 -->{{ $fanyi('单价') }}</li>
          <li><!-- 数量 -->{{ $fanyi('数量') }}</li>
          <li><!-- 总价 -->{{ $fanyi('总价') }}</li>
          <li><!-- 操作 -->{{ $fanyi('操作') }}</li>
        </ul>
      </div>
      <!-- 购物车有商品 -->
      <div class="lists" v-if="lists != false">
        <div class="item" v-for="(item, index) in lists" :key="index">
          <h4>
            <input type="checkbox" name="" id="" v-model="item.checked" @change="shopAll(index)" />
            {{ $fanyi('店铺') }} ：{{ item.shop_name }}
            <router-link to="/">{{ item.goods_title }}</router-link>
            <img src="../../assets/img/index2_143.png" alt="" v-if="item.type == '1688'" />
            <span class="img1688box"><img src="../../assets/al.svg" alt=""></span>
          </h4>
          <!-- 单个商品 -->
          <div class="list">
            <ul v-for="(listItem, listIndex) in item.list" :key="listIndex">
              <li class="checkBox">
                <div class="check">
                  <input type="checkbox" v-model="listItem.checked" @change="oddChoice(index)" />
                </div>
                <div class="indexs">{{ listItem.index }}</div>
                <div class="img">
                  <router-link :to="'/productDetails?goods_id=' +
                    listItem.goods_id +
                    '&shop_type=' +
                    listItem.shop_type
                    " target="_blank">
                    <el-popover placement="right" trigger="hover">
                      <img :src="listItem.pic" alt="" style="width: 300px; height: 300px" />
                      <img :src="listItem.pic" alt="" slot="reference" />
                    </el-popover>
                  </router-link>
                </div>
              </li>

              <li class="goods">
                <div class="text">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <p>
                      <router-link :to="'/productDetails?goods_id=' +
                        listItem.goods_id +
                        '&shop_type=' +
                        listItem.shop_type
                        " target="_blank">{{ listItem.goods_title }}
                      </router-link>
                    </p>shop_type
                    <p :title="listItem.goods_title" slot="reference">
                      <router-link style="font-weight: 400" :to="'/productDetails?goods_id=' +
                        listItem.goods_id +
                        '&shop_type=' +
                        listItem.shop_type
                        " target="_blank">{{ listItem.goods_title }}
                      </router-link>
                    </p>
                  </el-popover>
                  <p></p>
                  <input class="bei" type="text" :placeholder="$fanyi('备注栏')" v-model="listItem.user_postscript"
                    @blur="editCartRemark(listItem)" />
                </div>
              </li>
              <!-- 商品详情 -->
              <li class="detail">
                <el-popover placement="bottom" trigger="hover">
                  <div class="goodsDetailAll">
                    <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in listItem.detail"
                      :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                      <h1 class="detailTitle">{{ detailItem.key }}</h1>
                      <p>
                        <span class="detailBody">{{ detailItem.value }}</span>
                      </p>
                    </div>
                  </div>
                  <div slot="reference" class="DetailAll">
                    <p v-for="(detailItem, detailIndex) in listItem.detail" :key="detailIndex" style="font-weight: 400"
                      :title="detailItem.key + ':' + detailItem.value">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>
                  </div>
                </el-popover>
              </li>
              <li class="price">
                <div class="price-box">
                  <!-- 单价 -->
                  <div class="danjia">
                    <b>
                      <!-- {{ Number(listItem.price) }} -->

                      ${{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            (listItem.univalence != 0
                              ? listItem.univalence
                              : Number(listItem.price)) *
                            $store.state.exchangeRate
                          )
                        )
                      }}
                      ({{
                        $fun.RMBNumSegmentation(
                          listItem.univalence != 0
                            ? listItem.univalence
                            : Number(listItem.price)
                        )
                      }}￥)

                    </b>
                  </div>
                  <!-- > = x 件 ¥x -->
                  <div v-if="listItem.price_range && !listItem.price_range.priceMin
                  ">
                    <!-- v-show="
                        listItem.num < priceItem.startQuantity &&
                        listItem.num >
                          (listItem.price_range[priceIndex + 1]
                            ? listItem.price_range[priceIndex + 1].startQuantity
                            : '')
                      " -->
                    <p v-for="(priceItem, priceIndex) in listItem.price_range" :key="priceIndex" v-show="panduanxianshi(listItem.num, listItem.price_range) ==
                      priceIndex
                      ">
                      >=<b> {{ priceItem.startQuantity }}</b>
                    </p>
                  </div>
                  <!-- 最大值-最小值 -->
                  <!-- <p v-else>
                    ${{
                      $fun.EURNumSegmentation(
                        $fun.ceil(
                          Number(listItem.price_range.priceMax) *
                            $store.state.exchangeRate
                        )
                      )
                    }}({{
                      $fun.RMBNumSegmentation(listItem.price_range.priceMax)
                    }}￥)~${{
                      $fun.EURNumSegmentation(
                        $fun.ceil(
                          Number(listItem.price_range.priceMin) *
                            $store.state.exchangeRate
                        )
                      )
                    }}({{
                      $fun.RMBNumSegmentation(listItem.price_range.priceMin)
                    }}￥)
                  </p> -->
                </div>
              </li>
              <!-- 选择数量 -->
              <li style="text-align: center" class="setNum">
                <el-input-number v-model="listItem.num" :step="1" step-strictly size="mini" :min="1"
                  :max="listItem.kuCun" @change="numAmend(index, listIndex, listItem)"></el-input-number>
              </li>
              <!-- 总价 -->
              <li style="text-align: center">
                <div class="zongjia">
                  <span>${{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(listItem.total * $store.state.exchangeRate)
                    )
                  }}</span><br />
                  <span style="font-weight: 400">
                    ({{ $fun.RMBNumSegmentation(listItem.total) }}¥)</span>
                </div>
              </li>
              <li style="text-align: center">
                <a href="javascript:;" class="del" @click="delCart(listItem.cart_id, index, listIndex)">
                  <img src="../../assets/newImg/delete_0.png" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img src="../../assets/img/gwcc.png" alt="" />
          <div class="nav">
            <p>{{ $fanyi('购物车为空') }}</p>
            <p>
              <router-link to="/">{{ $fanyi('去购物') }} |</router-link>
              <router-link to="/user/commodity">
                {{ $fanyi('商品库') }}</router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="foot" :class="{ order: this.$route.name != 'cart' }">
        <input type="checkbox" v-model="checked" @change="allChoice" /><b class="quanXuan">{{ $fanyi('全选/取消') }}</b>
        <b @click="delCart(false)" class="del"> {{ $fanyi('删除') }}</b>
        <div class="total">
          <div class="goodsNum">
            <span class="mm">
              {{ $fanyi('商品种类') }}： <span>{{ total }}</span>
            </span>
            <span class="mm">
              {{ $fanyi('数量总计') }}： <span>{{ isTotal }}</span>
            </span>
          </div>
        </div>
        <div class="total zongjia">
          <div>
            <!-- 总价: -->
            <p>
              <span class="label"> {{ $fanyi('总价') }}：</span>
              <span class="RMBTotal">
                ￥{{ $fun.RMBNumSegmentation($fun.ceil(totalPrice)) }}
              </span>
            </p>
            <p>
              <span style="color: #999" class="label">
                (1￥≈${{ $store.state.exchangeRate }})</span>
              <span class="price">${{
                $fun.EURNumSegmentation(
                  $fun.ceil(totalPrice * $store.state.exchangeRate)
                )
              }}
              </span>
            </p>
          </div>
        </div>
        <button :disabled="idArr.length == 0" :class="{ btn: idArr.length > 0 }" @click="jieSuan"
          v-if="this.$route.name == 'cart'">
          {{ $fanyi('结算') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import HomePageTop from '../../components/head/HomePageTop'
import Dialog from '../../components/public/Dialog'
export default {
  data() {
    return {
      token: localStorage.getItem('token'),
      checked: false, //全选
      type: 0, // 类型
      num: 0, //
      total: 0, // 商品的数量
      isTotal: 0, // 选中的商品数
      shopTotal: 0, // 选中的店铺
      totalPrice: 0, // 选中的商品总价
      lists: null, // 数据
      idArr: [], // 选中的ID
      cart: true,
      // 弹窗的属性
      config: {
        top: '35vh',
        width: '480px',
        title: this.$fanyi('删除提示'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
    }
  },
  computed: {},
  components: {
    HomePageTop,
    Dialog,
  },
  created() {

    this.getCartList()
    if (this.$route.name == 'OrderDetails') {
      this.cart = false
    }
  },
  methods: {
    toHome() {
      sessionStorage.setItem('toHot', true)
      this.$fun.toPage('/')
    },
    // 判断显示那个价格区间
    panduanxianshi(num, arr) {

      if (num < arr[0].startQuantity) {
        return 0
      }
      let s = 0
      arr.forEach((item, index) => {
        if (num >= arr[index].startQuantity) {
          s = index
        }
      })
      return s
    },
    // 订单购物车获取数据
    getCartFrom() {
      let ids = []
      this.$parent.newList.forEach((item) => {
        ids.push(item.id)
      })
      let datas = {
        token: this.token,
        ids: ids.join(','),
      }
    },
    // 获取数据
    getCartList() {
      if (this.$route.name != 'cart') {
        this.$api
          .goodsCartList({
            not_ids: this.$route.query.id,
          })
          .then((res) => {
            if (res.code != 0) {
              this.lists = []
              return false
            }
            this.lists = res.data
            this.tidyData()
          })
      } else {
        this.$api.goodsCartList({ only_list: 'yes' }).then((res) => {
          if (res.code != 0) {
            this.lists = []
            return false
          }
          let ind = 1

          res.data.forEach((item) => {
            item.list.forEach((i) => {
              i.index = ind
              ind++
            })
          })

          this.lists = res.data
          this.tidyData()
        })
      }
    },

    // 整理数据
    tidyData() {
      this.lists.forEach((item) => {
        item.type = item.list[0].type // 店铺类别
        item.list.forEach((item1) => {
          // 商品总数
          item1.checked = false // 商品选择
          item1.univalence = 0 // 显示单价
          item1.total = 0

          let detailStraight = ((detail) => {

            let str = []
            detail.forEach((detailitem) => {
              str.push(detailitem.value)
            })
            return '"' + str.join('㊖㊎') + '"'
          })(item1.detail)

          item1.goods_inventory.forEach((ind, ii) => {



            ind.valueC?.forEach((i) => {
              if (i.check == true) {

                item1.kuCun = i.amountOnSale

              }
            })


          })



          // item1.price_range = JSON.parse(item1.price); // 字符串转换组数
        })
      })
      this.subtotal()
    },
    // 修改数量
    numAmend(i, index, item) {
      //   数量不能小于最小数
      if (item.num < 1 || item.num == undefined) {
        this.lists[i].list[index].num = item.price_range[0].startQuantity
        return this.$message.error(this.$fanyi('数量不能小于最小数'))
      }
      this.subtotal()
      let datas = {
        num: item.num,
        cart_id: item.cart_id,
        price: item.univalence == 0 ? item.price : item.univalence,
      }
      this.$forceUpdate()
      this.$api.goodsCartEditNum(datas).then((res) => {
        if (res.code != 0) return
        this.commodityTotal()
      })
    },
    // 总价
    subtotal() {

      this.lists.forEach((item) => {
        item.list.forEach((listItem) => {
          if (listItem.price_range.length == 1) {
            if (!isNaN(listItem.price_range[0])) {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[0]
              )
              listItem.univalence = listItem.price_range[0]
            } else {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[0].price
              )
              listItem.univalence = listItem.price_range[0].price
            }
          } else if (listItem.price_range.length == 2) {
            if (listItem.num < listItem.price_range[1].startQuantity) {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[0].price
              )
              listItem.univalence = listItem.price_range[0].price
            } else if (listItem.num >= listItem.price_range[1].startQuantity) {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[1].price
              )
              listItem.univalence = listItem.price_range[1].price
            }
          } else if (listItem.price_range.length == 3) {
            if (listItem.num < listItem.price_range[1].startQuantity) {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[0].price
              )
              listItem.univalence = listItem.price_range[0].price
            } else if (
              listItem.num >= listItem.price_range[1].startQuantity &&
              listItem.num < listItem.price_range[2].startQuantity
            ) {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[1].price
              )
              listItem.univalence = listItem.price_range[1].price
            } else if (listItem.num >= listItem.price_range[2].startQuantity) {
              listItem.total = this.$fun.ceil(
                listItem.num * listItem.price_range[2].price
              )
              listItem.univalence = listItem.price_range[2].price
            }
          }
          if (listItem.univalence == 0) {
            listItem.total = this.$fun.ceil(listItem.num * listItem.price)
          }
        })
      })
    },

    // 单选
    oddChoice(i) {
      let flag = this.lists[i].list.every((item) => item.checked == true)
      if (flag) {
        this.lists[i].checked = true
      } else {
        this.lists[i].checked = false
      }
      this.isCheckedAll()
      this.commodityTotal()
      this.$forceUpdate()
      //   flag ? (this.lists[i].checked = true) : (this.lists[i].checked = false);
    },
    // 店铺全选
    shopAll(i) {
      if (this.lists[i].checked) {
        this.lists[i].list.forEach((item) => {
          item.checked = true
        })
      } else {
        this.lists[i].list.forEach((item) => {
          item.checked = false
        })
      }
      this.$forceUpdate()
      this.isCheckedAll()
      this.commodityTotal()
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true
          this.shopAll(index)
        })
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false
          this.shopAll(index)
        })
      }
      this.commodityTotal()
      this.$forceUpdate()
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true)
      if (flag == true) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.totalPrice = 0
      this.isTotal = 0
      this.shopTotal = 0
      this.total = 0

      this.idArr = []
      this.lists.forEach((item, i) => {

        if (item.list.some((items) => items.checked)) {
          this.shopTotal += 1
        }
        item.list.forEach((listItem, j) => {
          if (listItem.checked) {
            this.total++
            this.totalPrice += listItem.total - 0
            this.isTotal += listItem.num
            this.idArr.push(listItem.cart_id)
          }
        })
      })

    },
    // 删除
    delCart(id, shopIndex, goodsIndex) {
      let datas = {
        cart_id: '',
      }
      console.log(id);

      let msg = this.$fanyi('确认删除该商品吗？')
      if (id) {
        datas.cart_id = id
      } else {
        if (this.idArr == false)
          return this.$message.error(this.$fanyi('未选择操作的商品'))
        datas.cart_id = this.idArr.join(',')
        msg = this.$fanyi('是否将选中的商品移除购物车?')
      }
      this.$refs.dialog.open(
        // 文本,复杂的在组件里添加插槽
        msg,
        () => {
          // 确认按钮事件
          this.$api.goodsCartDelete(datas).then((res) => {
            if (res.code != 0) return
            this.$message({
              type: 'success',
              message: this.$fanyi('您已成功移除选中的商品'),
            })
            this.getCartList()
            this.commodityTotal()
            this.$store.dispatch('goodsToCartNum')
            // }
          })
        },
        () => { }
      )
    },

    // 备注商品信息
    editCartRemark(item) {
      if (item.beikao == '') return false
      let datas = {
        cart_id: item.cart_id,
        user_postscript: item.user_postscript,
      }
      this.$api.goodsCartPostscript(datas).then((res) => {
        if (res.code != 0) return false
      })
    },
    // 结算
    jieSuan() {
      if (this.idArr.length > 60) {
        return this.$message.warning(
          this.$fanyi('单个订单不能超过60番产品，请调整提出番数')
        )
      }
      let id = this.idArr.join(',')
      this.$fun.toPage('/orderDetails?id=' + id + '&type=temporary')
    },
    // 追加到购物车
    zhuijia() {
      let ids = this.idArr.join(',').split(',')
      // 根据路由上有没有id属性来判断是提出订单还是临时保存订单，临时保存订单需要循环数据获取商品id，提出订单获取路由上的id
      if (this.$route.query.id) {
        var nowIds = this.$route.query.id.split(',')
      } else {
        var nowIds = []
        this.$parent.$parent.newList.forEach((item) => {
          item.list.forEach((etem) => {
            nowIds.push(etem.id)
          })
        })

      }
      let new_arr = this.$fun.quChu(ids, nowIds)
      if (new_arr.length == 0) return (this.$parent.$parent.showCart = false)
      new_arr = new_arr.join(',')
      this.$parent.$parent.addTo(new_arr)
      this.$parent.$parent.showCart = false
    },
  },
}
</script>
<style lang="scss">
body {
  background-color: #f6f6f6 !important;
}
</style>
<style lang="scss" scoped>
@import '../../css/mixin';

.zongjia {
  span {
    color: #222222;
  }
}

/deep/.el-dialog__footer {
  padding-bottom: 40px;
}

.main {
  width: $pageWidth;
  margin: 0px auto 80px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba (61, 61, 61, 0.1);
  border-radius: 0px;
  padding: 30px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;

  &.hei {
    height: 450px;
    overflow: auto;
  }

  input {
    vertical-align: middle;
  }

  .type {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    button {
      background-color: transparent;
      letter-spacing: 0px;
      font-size: 18px;
      font-weight: 500;
      color: #222222;
    }

    img {
      width: 132px;
      height: 20px;
    }

  }

  input {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .head {
    // top的设置在行内样式里
    height: 60px;
    position: sticky;
    top: 81px !important;
    left: 0;
    background: #f6f6f6;
    z-index: 4;
    border-radius: 6px;

    ul {
      display: flex;
      line-height: 56px;
      font-size: 14px;
      font-weight: 500;
      color: #222222;
      line-height: 60px;

      li {
        margin-right: 20px;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }

      .chexk-box {
        text-align: left;
        display: flex;
        padding-left: 20px;
        align-items: center;

        input {
          margin-right: 10px;
          margin-left: 0;
        }
      }

      .goods {
        flex: 0 0 300px;
      }

      li {
        flex: 1;
        text-align: center;

        input {
          margin-left: 10px;
        }

        &.detail {
          flex: 0 0 200px;
        }
      }
    }
  }

  .lists {

    // background-color: pink;
    .item {
      border-bottom: 1px solid #e2e2e2;

      h4 {
        font-size: 14px;
        line-height: 64px;
        width: 100%;
        display: flex;
        align-items: center;


        input {
          margin: 0 20px;
        }

        a {
          color: #222;

          &:hover {
            color: #999;
          }
        }

        img {
          margin-left: 5px;
          vertical-align: middle;
          float: none;
        }

        // 1688
        .img1688box {
          margin-left: 10px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .list {
        ul {
          height: 100px;
          display: flex;
          margin-bottom: 30px;

          &:hover {
            background: #f9f9f9;
            border-radius: 6px;
          }

          .checkBox {
            margin-right: 20px !important;

            input {
              margin-left: 20px;
            }

            .indexs {
              // padding: 0 20px;
              margin-right: 8px;
              width: 45px;
              text-align: center;
            }

            img {
              width: 80px;
              height: 80px;

              border-radius: 4px;
            }
          }

          .goods {
            width: 300px;

            .text {
              height: 80px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              input {
                padding-left: 10px;
                border-radius: 4px;
              }
            }
          }

          li {
            word-break: break-all;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            &:nth-last-child(1) {
              margin-right: 0;
            }

            .check {
              height: 80px;
              display: flex;
              align-items: center;
            }

            &:first-child {
              display: flex;
              margin-right: 10px;
            }

            &.detail {
              position: relative;
              flex: 0 0 200px;

              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: column;
              background-color: #f9f9f9;
              padding: 10px;
            }

            .goods {
              position: relative;
              // > div {
              //   position: absolute;
              //   left: 50%;
              //   transform: translateX(-50%);
              //   text-align: left;
              //   font-size: 14px;
              //   color: #000000;
              //   line-height: 19px;
              //
              // }
            }

            a {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .bei {
              width: 300px;
              height: 30px;
              border-radius: 0px;
              border: 1px solid rgba($color: #182280, $alpha: 0.3);
            }

            span {
              color: #ffa018;
              font-weight: 700;
            }
          }

          .del {
            &:hover {
              opacity: 0.3;
            }
          }

          .price {
            .price-box {
              .danjia {
                padding: 0 15px;
                text-align: center;
              }

              p {
                text-align: center;
                padding: 0 10px;
              }

              font-size: 14px;
              line-height: 19px;
            }
          }
        }

        // background-color: pink;
      }
    }
  }

  .cart {
    padding: 50px 0;
    text-align: center;
    overflow: hidden;

    >div {
      margin: 0 auto;
      width: 440px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    img {
      margin: 0 auto;
    }

    .nav {
      margin: 50px 0 0 30px;

      p {
        font-size: 20px;
        line-height: 50px;
        text-align: center;

        a {
          color: $homePageSubjectColor;
          font-size: 16px;
        }
      }
    }
  }

  .foot {
    width: 1400px;
    height: 100px;
    background: #fff;

    padding-right: 20px;
    box-shadow: 0 0 10px 0rgba (61, 61, 61, 0.1);
    border-radius: 6px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    line-height: 50px;
    font-weight: 700;
    z-index: 99;
    display: flex;
    align-items: center;
    border: 1px solid #e2e2e2;

    input {
      margin-left: 40px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    b {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 40px;
      height: 40px;

      &.quanXuan {
        font-weight: 400;
      }

      &.del {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        background: #608bff;
        border-radius: 4px;
        line-height: 26px;
        margin-left: 40px;
        border: 1px solid #608bff;
        padding: 6px 24px;
      }
    }

    .total {
      margin: 0 auto 0 0;
      font-weight: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding-left: 0px;

      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 26px;
      }

      span {
        font-size: 14px;

        // margin: 0 10px;
      }

      .goodsNum {
        position: relative;

        .mm {
          line-height: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #222222;
          margin-left: 60px;
        }
      }
    }

    .zongjia {
      text-align: right;
      margin-right: 40px;

      p {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        line-height: 20px;

        .label {
          width: 100px;
          text-align: right;
        }

        span {
          font-weight: 400;
        }

        .price {
          font-size: 24px;
          font-weight: bold;
          color: #ff730b;
          line-height: 36px;

          margin-left: 10px;

          span {
            font-size: 24px;
          }
        }

        .RMBTotal {
          margin-left: 10px;
          font-size: 14px;
          color: #222222;
          line-height: 20px;
        }
      }
    }

    i {
      margin: 0 20px;
    }

    button {
      width: 200px;
      height: 60px;
      background: #ff730b;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      line-height: 24px;
      cursor: not-allowed;
    }

    .btn {
      background-color: #ff730b;
      cursor: pointer;
    }
  }

  .order {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.DetailAll {
  padding: 0 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  height: 300px;
  font-size: 14px;
  color: #000000;
  line-height: 19px;

  max-height: 79px;
  // border: solid;
  width: 100%;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-align: center;

  p {
    margin: 0 auto;
    // width: 190px;
    text-align: left;
    word-break: break-all;
  }
}
</style>
